<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        a) Water is considered a polar solvent. Which of the following substances should dissolve in
        water?
      </p>

      <div v-for="(molecule, i) in molecules" :key="'pt-1-' + i">
        <v-checkbox v-model="inputs.input1" :value="i" class="mb-n4 mt-0 pl-8">
          <template v-slot:label>
            <chemical-latex style="font-size: 13px" :content="molecule" />
          </template>
        </v-checkbox>
      </div>

      <p class="mb-2 mt-5">
        b) Toluene,
        <chemical-latex content="C6H5CH3," />
        is widely used in industry as a non-polar solvent. Which of the following substances should
        dissolve in toluene?
      </p>

      <div v-for="(molecule, i) in molecules" :key="'pt-2-' + i">
        <v-checkbox v-model="inputs.input2" :value="i" class="mb-n4 mt-0 pl-8">
          <template v-slot:label>
            <chemical-latex style="font-size: 13px" :content="molecule" />
          </template>
        </v-checkbox>
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question407',
  components: {
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
        input2: [],
      },
      mol1options: ['Methanol (CH3OH)', 'Ethanol (C2H5OH)'],
      mol2options: ['Sodium sulfate (Na2SO4)', 'Potassium nitrate (KNO3)'],
      mol3options: [
        'Octane (C8H18), a non-polar organic compound',
        'Nonane (C9H20), a non-polar organic compound',
      ],
      mol4options: ['Carbon tetrachloride (CCl4)', 'Iodine (I2)'],
    };
  },
  computed: {
    mcOptionV1() {
      return this.taskState.getValueBySymbol('mcOptionV1').content;
    },
    mcOptionV2() {
      return this.taskState.getValueBySymbol('mcOptionV2').content;
    },
    mcOptionV3() {
      return this.taskState.getValueBySymbol('mcOptionV3').content;
    },
    mcOptionV4() {
      return this.taskState.getValueBySymbol('mcOptionV4').content;
    },
    molecules() {
      return [
        this.mol1options[this.mcOptionV1.value - 1],
        this.mol2options[this.mcOptionV2.value - 1],
        this.mol3options[this.mcOptionV3.value - 1],
        this.mol4options[this.mcOptionV4.value - 1],
      ];
    },
  },
};
</script>
